import React from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Typography, Button, Box, Paper } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

const JustificativaConfirmada = () => {
  const location = useLocation();
  const { formData, nomeArquivo } = location.state || {};

  const imprimirComprovante = () => {
    window.print(); // Abre a janela de impressão
  };

  return (
    <Container maxWidth="sm" style={{ marginTop: '2rem' }}>
      <Box display="flex" justifyContent="center" mb={2}>
        <img src="/LOGO.png" alt="Logo" style={{ width: '200px', height: 'auto' }} />
      </Box>
      <Paper elevation={3} style={{ padding: '2rem' }}>
        <Typography gutterBottom align="center" fontSize={'1.4em'}>
          Justificativa Registrada
        </Typography>
        <Box mb={2}>
          <Typography variant="body2"><strong>Nome Completo:</strong> {formData.nomeCompleto}</Typography>
          <Typography variant="body2"><strong>Turma:</strong> {formData.turma}</Typography>
          <Typography variant="body2"><strong>Data da Aula:</strong> {format(parseISO(formData.dataAula + 'T00:00:00'), "EEEE, dd/MM/yyyy", { locale: ptBR })}</Typography>
          <Typography variant="body2"><strong>Justificativa:</strong> {formData.justificativa}</Typography>
          <Typography variant="body2"><strong>Documento Anexado:</strong> {nomeArquivo ? `Sim` : 'Não'}</Typography>
        </Box>
        <Typography 
          variant="body2" 
          align="center" 
          color="error" 
          style={{ marginBottom: '1rem' }}
        >
          Por favor, guarde este comprovante.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={imprimirComprovante}
        >
          Imprimir Comprovante
        </Button>
      </Paper>
    </Container>
  );
};

export default JustificativaConfirmada;
