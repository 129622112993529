import React from 'react';
import DashboardComponent from '../components/Dashboard';
import { Helmet } from 'react-helmet';

const Dashboard = () => {
  return (
    <div>
      <Helmet>
      <title>Sistema ESCT - Dashboard</title>
      <link rel="icon" type="image/png" href="http://www.esct.net.br/wp-content/uploads/2021/02/cropped-LOGO-ONG-32x32.png" sizes="16x16" />

    </Helmet>
      <DashboardComponent />
    </div>
  );
};

export default Dashboard;
