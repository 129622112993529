// src/index.js
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import "./style.css"
import { ThemeProvider, createTheme } from '@mui/material/styles';
import axios from 'axios';

const theme = createTheme({
  palette: {
    mode: 'light', // ou 'dark' se você quiser o modo escuro
  },
});


// Adicionando um interceptor para adicionar o token JWT a todas as requisições
axios.interceptors.response.use(
  response => response, // Retorna a resposta normalmente se não houver erro
  error => {
    if (error.response && error.response.status === 401) {
      // Remove o token do localStorage e redireciona para a página de login
      localStorage.removeItem('token');
      window.location.href = '/login'; // Redireciona para a página de login
    }
    return Promise.reject(error);
  }
);


ReactDOM.render(
  <ThemeProvider theme={theme}>
  <Router>
    <App />
  </Router>,
  </ThemeProvider>,

  document.getElementById('root')
);
