// src/pages/AddLicitacao.js
import React from 'react';
import AddLicitacaoForm from '../components/AddLicitacaoForm';
import { Helmet } from 'react-helmet';

const AddLicitacao = () => {
  return (
    <div>
      <Helmet>
        <title>Sistema ESCT - Adicionar Licitação</title>
        <link rel="icon" type="image/png" href="http://www.esct.net.br/wp-content/uploads/2021/02/cropped-LOGO-ONG-32x32.png" sizes="16x16" />
      </Helmet>
      <AddLicitacaoForm />
    </div>
  );
};

export default AddLicitacao;
