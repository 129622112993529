import React from 'react';
import AdminDashboard from '../components/AdminDashboard';
import { Helmet } from 'react-helmet';


const Admin = () => {
  return (
    <div>
      <Helmet>
      <title>Sistema ESCT - Justificativa</title>
      <link rel="icon" type="image/png" href="http://www.esct.net.br/wp-content/uploads/2021/02/cropped-LOGO-ONG-32x32.png" sizes="16x16" />

    </Helmet>
      <AdminDashboard />
    </div>
  );
};

export default Admin;
