import React, { useState } from 'react';
import { Routes, Route, Link, Outlet, useNavigate, useLocation } from 'react-router-dom';
import { Drawer, List, ListItem, ListItemText, ListItemIcon, Divider, Box, Typography, Collapse, IconButton, AppBar, Toolbar, Menu, MenuItem, CssBaseline, Avatar } from '@mui/material';
import { Dashboard as DashboardIcon, Assignment as AssignmentIcon, Info as InfoIcon, ContactMail as ContactMailIcon, ExitToApp as ExitToAppIcon, Person as PersonIcon, ArrowDropDown as ArrowDropDownIcon, ArrowRight as ArrowRightIcon, Menu as MenuIcon, AccountCircle as AccountCircleIcon } from '@mui/icons-material';
import AdminDashboard from '../pages/Admin';
import Dashboard from '../pages/Dashboard';
import AddLicitacaoForm from '../pages/AddLicitacao';
import LicitacoesTable from '../pages/LicitacoesList';
import SegmentIcon from '@mui/icons-material/Segment';
import { Helmet } from 'react-helmet';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const drawerWidth = 240;

const Layout = () => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const navigate = useNavigate();
  const location = useLocation();
  const [openJustificativas, setOpenJustificativas] = useState(false);
  const [openLicitacoes, setOpenLicitacoes] = useState(false);
  const [openCoordenacao, setOpenCoordenacao] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    navigate('/login');
  };

  const handleJustificativasClick = () => {
    setOpenJustificativas(!openJustificativas);
  };

  const handleLicitacoesClick = () => {
    setOpenLicitacoes(!openLicitacoes);
  };

  const handleCoordenacaoClick = () => {
    setOpenCoordenacao(!openCoordenacao);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const drawer = (
    <div>
      <Toolbar />
      <List>
        <ListItem button onClick={handleJustificativasClick}>
          <ListItemIcon>
            <AssignmentIcon sx={{ color: 'white' }} />
          </ListItemIcon>
          <ListItemText primary="Sistema de Justificativas" primaryTypographyProps={{ fontWeight: 'bold', color: 'white' }} />
          {openJustificativas ? <ArrowDropDownIcon sx={{ color: 'white' }} /> : <ArrowRightIcon sx={{ color: 'white' }} />}
        </ListItem>
        <Collapse in={openJustificativas} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button component={Link} to="/admin/dashboard" selected={location.pathname === '/admin/dashboard'} sx={{ pl: 4, backgroundColor: location.pathname === '/admin/dashboard' ? 'white' : 'inherit', borderRadius: location.pathname === '/admin/dashboard' ? 1 : 0, m: location.pathname === '/admin/dashboard' ? 1 : 0, '&.Mui-selected': { backgroundColor: 'white', color: 'primary.main', '&:hover': { backgroundColor: 'white' } } }}>
              <ListItemText primary="Início" primaryTypographyProps={{ fontWeight: 500, color: location.pathname === '/admin/dashboard' ? 'primary.main' : 'white' }} />
            </ListItem>
            <ListItem button component={Link} to="/admin/justificativas" selected={location.pathname === '/admin/justificativas'} sx={{ pl: 4, backgroundColor: location.pathname === '/admin/justificativas' ? 'white' : 'inherit', borderRadius: location.pathname === '/admin/justificativas' ? 1 : 0, m: location.pathname === '/admin/justificativas' ? 1 : 0, '&.Mui-selected': { backgroundColor: 'white', color: 'primary.main', '&:hover': { backgroundColor: 'white' } } }}>
              <ListItemText primary="Lista de Justificativas" primaryTypographyProps={{ fontWeight: 500, color: location.pathname === '/admin/justificativas' ? 'primary.main' : 'white' }} />
            </ListItem>
          </List>
        </Collapse>
        <ListItem button onClick={handleLicitacoesClick}>
          <ListItemIcon>
            <ContactMailIcon sx={{ color: 'white' }} />
          </ListItemIcon>
          <ListItemText primary="Administração" primaryTypographyProps={{ fontWeight: 'bold', color: 'white' }} />
          {openLicitacoes ? <ArrowDropDownIcon sx={{ color: 'white' }} /> : <ArrowRightIcon sx={{ color: 'white' }} />}
        </ListItem>
        <Collapse in={openLicitacoes} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button component={Link} to="/admin/licitacoes/add" selected={location.pathname === '/admin/licitacoes/add'} sx={{ pl: 4, backgroundColor: location.pathname === '/admin/licitacoes/add' ? 'white' : 'inherit', borderRadius: location.pathname === '/admin/licitacoes/add' ? 1 : 0, m: location.pathname === '/admin/licitacoes/add' ? 1 : 0, '&.Mui-selected': { backgroundColor: 'white', color: 'primary.main', '&:hover': { backgroundColor: 'white' } } }}>
              <ListItemText primary="Adicionar Licitação" primaryTypographyProps={{ fontWeight: 500, color: location.pathname === '/admin/licitacoes/add' ? 'primary.main' : 'white' }} />
            </ListItem>
            <ListItem button component={Link} to="/admin/licitacoes/list" selected={location.pathname === '/admin/licitacoes/list'} sx={{ pl: 4, backgroundColor: location.pathname === '/admin/licitacoes/list' ? 'white' : 'inherit', borderRadius: location.pathname === '/admin/licitacoes/list' ? 1 : 0, m: location.pathname === '/admin/licitacoes/list' ? 1 : 0, '&.Mui-selected': { backgroundColor: 'white', color: 'primary.main', '&:hover': { backgroundColor: 'white' } } }}>
              <ListItemText primary="Listar Licitações" primaryTypographyProps={{ fontWeight: 500, color: location.pathname === '/admin/licitacoes/list' ? 'primary.main' : 'white' }} />
            </ListItem>
          </List>
        </Collapse>
        <ListItem button onClick={handleCoordenacaoClick}>
          <ListItemIcon>
            <SegmentIcon sx={{ color: 'white' }} />
          </ListItemIcon>
          <ListItemText primary="Coordenação" primaryTypographyProps={{ fontWeight: 'bold', color: 'white' }} />
          {openCoordenacao ? <ArrowDropDownIcon sx={{ color: 'white' }} /> : <ArrowRightIcon sx={{ color: 'white' }} />}
        </ListItem>
        <Collapse in={openCoordenacao} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button component={Link} to="/admin/ciclos" selected={location.pathname === '/admin/ciclos'} sx={{ pl: 4, backgroundColor: location.pathname === '/admin/ciclos' ? 'white' : 'inherit', borderRadius: location.pathname === '/admin/ciclos' ? 1 : 0, m: location.pathname === '/admin/ciclos' ? 1 : 0, '&.Mui-selected': { backgroundColor: 'white', color: 'primary.main', '&:hover': { backgroundColor: 'white' } } }}>
              <ListItemText primary="Escala dos Vigias" primaryTypographyProps={{ fontWeight: 500, color: location.pathname === '/admin/ciclos' ? 'primary.main' : 'white' }} />
            </ListItem>
          </List>
        </Collapse>
        <ListItem button component={Link} to="#" selected={location.pathname === '#'} sx={{ backgroundColor: location.pathname === '#' ? 'white' : 'inherit', borderRadius: location.pathname === '#' ? 1 : 0, m: location.pathname === '#' ? 1 : 0, '&.Mui-selected': { backgroundColor: 'white', color: 'primary.main', '&:hover': { backgroundColor: 'white' } } }}>
          <ListItemIcon>
            <InfoIcon sx={{ color: 'white' }} />
          </ListItemIcon>
          <ListItemText primary="Ajuda" primaryTypographyProps={{ fontWeight: 'bold', color: location.pathname === '#' ? 'primary.main' : 'white' }} />
        </ListItem>
      </List>
      <Divider />
    </div>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <Helmet>
        <title>Sistema ESCT</title>
        <link rel="icon" type="image/png" href="http://www.esct.net.br/wp-content/uploads/2021/02/cropped-LOGO-ONG-32x32.png" sizes="16x16" />
      </Helmet>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: theme.zIndex.drawer + 1 }}>
        <Toolbar>
          {isMobile && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          )}
          <img src="/LOGO1.png" alt="Logo" style={{ width: 150, marginRight: 16, display: isMobile ? 'none' : 'block' }} />
          <Typography variant="h6" noWrap style={{ flexGrow: 1 }}>
            {location.pathname === '/admin/dashboard' && 'Sistema de Justificativas'}
            {location.pathname === '/admin/justificativas' && 'Lista de Justificativas'}
            {location.pathname === '/admin/ciclos' && 'Escala dos Vigias'}
            {location.pathname === '/admin/licitacoes/add' && 'Adicionar Licitação'}
            {location.pathname === '/admin/licitacoes/list' && 'Listar Licitações'}
            {location.pathname === '#' && 'Ajuda'}
          </Typography>
          <div>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircleIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <Box sx={{ p: 2, pl: 5, pr: 5, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Avatar sx={{ width: 56, height: 56, mb: 2 }}>{user.nomeUsuario ? user.nomeUsuario[0] : ''}</Avatar>
                <Typography variant="subtitle1" style={{ fontWeight: 'bold', color: 'black' }}>{user.nomeUsuario}</Typography>
                <MenuItem onClick={handleLogout} sx={{ color: 'red' }}>Sair</MenuItem>
              </Box>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, marginTop: 8, backgroundColor: theme.palette.primary.main },
          }}
          open
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: theme.palette.primary.main },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` }, marginTop: 8 }}
      >
        <Toolbar />
        <Routes>
          <Route path="justificativas" element={<AdminDashboard />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="licitacoes/add" element={<AddLicitacaoForm />} />
          <Route path="licitacoes/list" element={<LicitacoesTable />} />
        </Routes>
        <Outlet />
      </Box>
    </Box>
  );
};

export default Layout;
