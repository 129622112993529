// src/pages/Login.js
import React from 'react';
import LoginForm from '../components/LoginForm';
import { Helmet } from 'react-helmet';

const Login = () => {
  return (
    <div>
      <Helmet>
        <title>Entrar</title>
        <link rel="icon" type="image/png" href="http://www.esct.net.br/wp-content/uploads/2021/02/cropped-LOGO-ONG-32x32.png" sizes="16x16" />
      </Helmet>
      <LoginForm />
    </div>
  );
};

export default Login;
